.resetPassword {
  position: absolute;
  top: 50%;
  left: 50%;
  width:500px;
  height:500px;
  transform: translateX(-50%)translateY(-50%);

  h1 {
    color: #fff;
    letter-spacing:1px;
    text-align:center;
  }
}