.errorNotification {
    padding: 14px;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 15px;
}