.diffComparisonHolder {
  display: block;
  width: 700px;
  margin: 0 auto;

  .diffHolder {
    background-color: #fff;
    height: 700px;
    padding: 20px;
    border-radius: 5px;
    overflow-y: auto;

    .diffList {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        color: #000;
        padding: 2px 0;
        .added {
          background: #e6ffeb;
        }
        .removed {
          background: #ffdce0;
        }
        .spacing {
          color: transparent;
        }
      }
    }
  }

  .buttonHolder {
    margin-top: 20px;

    button {
      width: 46%;
      display: inline-block;
      margin: 0 2%;
    }
  }
}