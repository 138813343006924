$mainButtonColor: #4a77d4;
$gradientButtonColor: #6eb6de;
$backgroundColor: #e6e6e6;
$white: #fff;

.btn {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 4px 10px 4px;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  background-color: $backgroundColor;
  background-image: -moz-linear-gradient(top, $white, $backgroundColor);
  background-image: -ms-linear-gradient(top, $white, $backgroundColor);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($white), to($backgroundColor));
  background-image: -webkit-linear-gradient(top, $white, $backgroundColor);
  background-image: -o-linear-gradient(top, $white, $backgroundColor);
  background-image: linear-gradient(to top, $white, $backgroundColor);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=$white, endColorstr=$backgroundColor, GradientType=0);
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border: 1px solid $backgroundColor;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  *margin-left: .3em;
  &:hover {
    background-color: $backgroundColor;
    color: #333333;
    text-decoration: none;
    -webkit-transition: background-position 0.1s linear;
    -moz-transition: background-position 0.1s linear;
    -ms-transition: background-position 0.1s linear;
    -o-transition: background-position 0.1s linear;
    transition: background-position 0.1s linear;
  }
  &:active {
    background-color: $backgroundColor;
  }
}
.btn.active {
  background-color: $backgroundColor;
}
.btn.disabled {
  background-color: $backgroundColor;
}
.btn[disabled] {
  background-color: $backgroundColor;
}
.btnLarge {
  padding: 9px 14px;
  font-size: 15px;
  line-height: normal;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.btnPrimary {
  color: $white;
  background-color: $mainButtonColor;
  background-image: -moz-linear-gradient(top, $gradientButtonColor, $mainButtonColor);
  background-image: -ms-linear-gradient(top, $gradientButtonColor, $mainButtonColor);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($gradientButtonColor), to($mainButtonColor));
  background-image: -webkit-linear-gradient(top, $gradientButtonColor, $mainButtonColor);
  background-image: -o-linear-gradient(top, $gradientButtonColor, $mainButtonColor);
  background-image: linear-gradient(to top, $gradientButtonColor, $mainButtonColor);
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr=$gradientButtonColor, endColorstr=$mainButtonColor, GradientType=0);
  border: 1px solid #3762bc;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.5);
  &:hover {
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    color: $white;
    filter: none;
    background-color: $mainButtonColor;
  }
  &:active {
    filter: none;
    background-color: $mainButtonColor;
  }
}
.btnPrimary.active {
  color: rgba(255, 255, 255, 0.75);
  filter: none;
  background-color: $mainButtonColor;
}
.btnPrimary.disabled {
  filter: none;
  background-color: $mainButtonColor;
}
.btnPrimary[disabled] {
  filter: none;
  background-color: $mainButtonColor;
}
.btnBlock {
  width: 100%;
  display: block;
}