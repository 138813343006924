.forgotPassword {
  position: absolute;
  top: 50%;
  left: 50%;
  width:300px;
  height:300px;
  transform: translateX(-50%)translateY(-50%);

  h1 {
    color: #fff;
    text-shadow: 0 0 10px rgba(0,0,0,0.3);
    letter-spacing:1px;
    text-align:center;
  }
}