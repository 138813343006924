.contentEditor {
  display: block;
  width: 700px;
  margin: 0 auto;

  textarea {
    width: 700px;
    height: 700px;
    padding: 10px;
    resize: vertical;
  }

  .buttonHolder {
    margin-top: 20px;

    button {
      width: 46%;
      display: inline-block;
      margin: 0 2%;
    }
  }
}