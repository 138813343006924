.successNotification {
    padding: 14px;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 15px;
}