.treeContainer {
  display: block;
  width: 700px;
  margin: 0 auto;
  background-color: #fafafa;
  color: #000;
  min-height: 700px;
  max-height: 700px;
  overflow-y: auto;

  .breadcrumbs {
    padding: 10px;
    border-bottom: 1px dotted;
  }

  .controls {
    padding: 10px;
    
    .left, .right {
      display: inline-block;
      width: 50%;

      svg {
        cursor: pointer;
      }
    }
    
    .right {
      text-align: right;

      svg {
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  .folderList {
    list-style: none;
    padding: 10px 20px;
    margin: 0;

    li {
      padding: 10px 0;
      border-bottom: 1px solid #000;
      cursor: pointer;

      .itemName {
        padding-left: 10px;
      }

      .domainLink {
        margin-left: 10px;
        font-size: 12px;
      }
    }
  }
}