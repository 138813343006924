.container {
  width: 1200px;
  margin: 0 auto;
  position: relative;

  .scrapeManager {
    margin-top: 100px;

    svg {
      cursor: pointer;
    }

    .tableHolder {
      position: relative;
      padding: 20px 20px 10px;
      background: #fff;
    }

    .rdt_TableHead {
      font-weight: 900;
    }

    > button {
      width: 200px;
      float: right;
      margin-top: 20px;
    }
  }

  .modalHolder {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 9;

    .modal {
      position: absolute;
      width: 1400px;
      height: 300px;
      background: #fff;
      border: 1px solid #000;
      z-index: 10;
      color: #000;
      padding: 50px;
      top: 40%;
      left: 50%;
      transform: translateY(-50%)translateX(-50%);

      div {
        span{
          width: 150px;
          display: inline-block;
        }
      }

      input {
        width: 200px;
      }

      .buttonHolder {
        text-align: center;

        button {
          width: 150px;
          display: inline-block;
          margin: 0 20px;
        }
      }
    }
  }
}