.login {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width:300px;
  height:300px;

  h1 {
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    letter-spacing: 1px;
    text-align: center;
  }

  p {
    margin: 0 0 10px;
    text-align: right;
    font-size: 14px;

    a{
      color: #fff;
      text-decoration: none;
    }
  }
}